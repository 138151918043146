<template>
    <v-container :class="{'pa-0': isInspectionsPage}">
        <h2 v-if="!isInspectionsPage">Preview</h2>
        <v-card height="517" outlined width="260">
            <v-card class="ma-3 inner-card" height="491" outlined width="236">
                <validation-observer v-if="form.pages && !loadingFilledForm" ref="observer"
                                     v-slot="{ invalid, handleSubmit }">
                    <v-form @submit.prevent="handleSubmit">
                        <vue-form-generator
                            :model="form" :options="formOptions"
                            :schema="getFormSchema()"
                            class="form" tag="div"/>
                        <v-divider/>
                        <v-card class="overflow-x-hidden overflow-y-auto" flat height="453">
                            <div v-for="(page, index) in form.pages" :key="index">
                                <div v-if="currentPage === index" class="pages">
                                    <v-card class="overflow-x-hidden overflow-y-auto" flat height="417">
                                        <vue-form-generator
                                            :model="page" :options="formOptions"
                                            :schema="getPageSchema(index)" tag="div"/>
                                        <v-row v-if="isLastPage && !isInspectionsPage" class="my-3" justify="center">
                                            <v-btn :disabled="invalid" color="primary" small type="submit">Done</v-btn>
                                        </v-row>
                                    </v-card>

                                    <v-footer absolute class="px-0">
                                        <v-row align="center" class="" justify="center" no-gutters>
                                            <v-col cols="4">
                                                <v-btn v-if="currentPage" text x-small @click="prevPage">
                                                    <v-icon>mdi-chevron-left</v-icon>
                                                    Page {{ index }}
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="4">
                                                <span class="page-number ma-auto">
                                                    Page {{ currentPage + 1 }} of {{ form.pages.length }}
                                                </span>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn v-if="!isLastPage" right text x-small @click="nextPage">
                                                    Page {{ index + 2 }}
                                                    <v-icon v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
                                                    <v-icon v-else>mdi-chevron-right</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-footer>
                                </div>
                            </div>
                        </v-card>
                    </v-form>
                </validation-observer>
                <v-progress-circular v-else class="progress-circular" indeterminate/>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>
import {ResponseType} from "@/modules/forms/enums/response-type"
import {UPDATE_INSPECTION_FORM} from "@/modules/app/store/mutation-types"
import {QuestionType} from "@/modules/forms/enums/question-type"
import {ValidationObserver} from 'vee-validate'
import {updateQuestionField} from "@/modules/forms/helpers/helpers";

export default {
    name: "KurccFormPreview",
    components: {
        ValidationObserver,
    },
    props: ['inspection'],
    data() {
        return {
            currentPage: 0,
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: false,
                validateAsync: false
            },
            updateQuestionField,
            loadingFilledForm: false
        }
    },
    computed: {
        form: {
            get() {
                return this.$store.state.forms.form
            },
            set(v) {
                this.$store.commit(UPDATE_INSPECTION_FORM, v)
            }
        },
        lastPageIndex() {
            return this.form.pages ? this.form.pages.length - 1 : 0
        },
        isLastPage() {
            return this.currentPage === this.lastPageIndex
        },
        isInspectionsPage() {
            return this.$route.name === 'inspections'
        }
    },
    watch: {
        lastPageIndex: {
            handler: function (v) {
                if (this.currentPage > v) {
                    this.currentPage = this.lastPageIndex
                }
            }
        }
    },
    methods: {
        nextPage() {
            this.currentPage++
        },
        prevPage() {
            this.currentPage--
        },
        getFormSchema() {
            return {
                groups: [
                    {
                        legend: this.form.title,
                        fields: []
                    }
                ]
            }
        },
        getPageSchema(pageIndex) {
            return {
                groups: [
                    {
                        legend: this.form.pages[pageIndex].title,
                        fields: this.generatePageItems(pageIndex)
                    }
                ]
            }
        },
        generatePageItems(pageIndex) {
            const fields = []
            const questions = this.form.pages[pageIndex].questions

            this.generatePageSubItems(pageIndex, fields, questions)
            return fields
        },
        generatePageSubItems(pageIndex, fields, questions, level = 0, grandparentIndex = null, parentIndex = null) {
            this.$lodash.forEach(questions, (question, selfIndex) => {
                if (question.type === QuestionType.check) {
                    this.generateCheck(pageIndex, grandparentIndex, parentIndex, selfIndex, fields, question)
                } else {
                    this.generateSection(pageIndex, parentIndex, selfIndex, fields, question, level)
                }
            })
        },
        generateSection(pageIndex, parentIndex, selfIndex, fields, question, level) {
            level++
            let indexes = {
                pageIndex,
                parentIndex,
                questionIndex: selfIndex,
            }

            fields.push(
                {
                    type: "KurccFormSection",
                    item: question,
                    indexes,
                },
            )
            if (!this.isRepeatableSection(question))
                this.generatePageSubItems(pageIndex, fields, question.questions, level, level === 2 ? parentIndex : null, selfIndex)
        },
        isRepeatableSection(question) {
            return question.options.repeatSection
        },
        generateCheck(pageIndex, grandparentIndex, parentIndex, selfIndex, fields, question) {
            fields.push(
                {
                    type: 'label',
                    label: this.generateCheckLabel(question),
                },
                this.prepareResponse(question, selfIndex, pageIndex, grandparentIndex, parentIndex)
            )
        },
        generateCheckLabel: function (question) {
            const arrow = this.$vuetify.rtl ? '←' : '→'
            return `<v-row no-gutters><div class="d-inline-block blue--text">${arrow}</div>
                    <div class="warning--text d-inline-block">${question.options.answerRequired ? '*' : ''}</div>
                    <div class="d-inline-block">${question.title ?? ''}</div></v-row>`;
        },
        concatIsRequiredRule(question, rules = '') {
            if (this.$lodash.isEmpty(rules))
                return question.options.answerRequired ? 'required' : ''
            else return question.options.answerRequired ? 'required|' + rules : rules
        },
        prepareResponse(question, questionIndex, pageIndex, grandparentIndex, parentIndex) {
            let obj = {}
            let model
            let indexes = {
                pageIndex,
                grandparentIndex,
                parentIndex,
                questionIndex,
            }

            if (!this.$lodash.isNil(grandparentIndex)) {
                model = `questions[${grandparentIndex}].questions[${parentIndex}].questions[${questionIndex}].answer`
            } else if (!this.$lodash.isNil(parentIndex)) {
                model = `questions[${parentIndex}].questions[${questionIndex}].answer`
            } else
                model = `questions[${questionIndex}].answer`

            if (ResponseType["Multiple choice"] === question.options.response.type)
                obj = {
                    type: "KurccFormMultiChoiceResponse",
                    model,
                    item: question,
                    indexes,
                    values: question.options.response.responseSet.items,
                    rules: this.concatIsRequiredRule(question)
                }

            if (ResponseType.Text === question.options.response.type)
                obj = {
                    type: "KurccFormText",
                    component: 'v-textarea',
                    model,
                    item: question,
                    indexes,
                    placeholder: "Enter text",
                    rows: 1,
                    rules: this.concatIsRequiredRule(question),
                }

            if (ResponseType.Number === question.options.response.type)
                obj = {
                    type: "KurccFormNumber",
                    model,
                    item: question,
                    indexes,
                    placeholder: "Enter number",
                    rules: this.concatIsRequiredRule(question, 'numeric'),
                }

            if (ResponseType.Checkbox === question.options.response.type)
                obj = {
                    type: "KurccFormCheckbox",
                    model,
                    item: question,
                    indexes,
                    rules: this.concatIsRequiredRule(question),
                }

            if (ResponseType.Photo === question.options.response.type)
                obj = {
                    type: "KurccFormImage",
                    model,
                    item: question,
                    indexes,
                    rules: this.concatIsRequiredRule(question),
                }

            if (ResponseType["Date & Time"] === question.options.response.type)
                obj = {
                    type: "KurccFormDateTimeResponse",
                    model,
                    item: question,
                    indexes,
                    rules: this.concatIsRequiredRule(question),
                }

            if (ResponseType.Dropdown === question.options.response.type) {
                obj = {
                    type: "KurccFormDropdownResponse",
                    model,
                    item: question,
                    indexes,
                    values: question.options.response.responseSet.items,
                    rules: this.concatIsRequiredRule(question)
                }
            }

            if (ResponseType.Annotation === question.options.response.type)
                obj = {
                    type: "KurccFormSignatureResponse",
                    model,
                    showImageInput: true,
                    item: question,
                    indexes,
                    rules: this.concatIsRequiredRule(question)
                }

            if (ResponseType.Signature === question.options.response.type)
                obj = {
                    type: "KurccFormSignatureResponse",
                    model,
                    item: question,
                    indexes,
                    rules: this.concatIsRequiredRule(question)
                }

            if (ResponseType["No response required"] === question.options.response.type)
                obj = {
                    type: "label",
                    label: `questions[${questionIndex}].title`,
                    model,
                    visible: false
                }

            return obj
        },
        loadFilledForm() {
            if (this.inspection) {
                this.loadingFilledForm = true
                this.$store.dispatch('getInspectionsFilledForm', {
                    id: this.inspection.id,
                    form_id: this.inspection.result_id
                }).finally(() => {
                    this.loadingFilledForm = false
                })
            }
        }
    },
    created() {
        this.loadFilledForm()
    }
}
</script>
<style lang="scss" scoped>
.v-footer {
    .v-btn {
        padding: 0;
    }
}
</style>

<style lang="scss">
.inner-card {
    position: relative;

    .progress-circular {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

ul.multiselect__content {
    padding: 0 !important;
}

.vue-form-generator {
    legend {
        margin: 5px auto;
        width: 100%;
        line-height: 25px;
    }
}

.form {
    legend {
        font-weight: bold;
        font-size: 14px;
    }
}

.pages {
    legend {
        font-size: 13px;
        width: 236px;
        border-color: rgba(0, 0, 0, 0.12);
        border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
    }

    .field-label {
        margin: 0;

        label > span {
            font-size: 12px;
            margin: 0;

            &:before {
                margin-left: -12px;
                margin-right: 3px;
                content: '\F054';
                display: inline-block;
                font: normal normal normal 24px/1 "Material Design Icons";
                font-size: inherit;
                text-rendering: auto;
                line-height: inherit;
                -webkit-font-smoothing: antialiased;
            }
        }

        .field-wrap {
            display: none;
        }
    }

    .form-group.field-label {
        text-align: start;
        margin-top: 10px;
    }

    .form-group {
        padding: 0 15px;
    }

    .page-number {
        font-size: 10px;
    }
}
</style>
